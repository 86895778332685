import React from "react";
import PropTypes from "prop-types";
import Helmet from 'react-helmet'
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import logoImg from "../images/logo-icon.png";
import { LogoImg } from "../components/Logo";


import { Layout, Article, Wrapper, Button, SectionTitle, Header } from "../components";
import {StyledH3, SecondaryH2 } from '../components/StyledElements';
import config from '../../config'

const Content = styled.div`
  grid-column: 2;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 3rem 6rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;
`;

const Hero = styled.div`
  grid-column: 2;
  padding: 3rem 2rem 6rem 2rem;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.grey.dark};

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1rem 4rem 1rem;
  }

  p {
    font-size: 1.68rem;
    margin-top: -1rem;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 1.45rem;
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 1.25rem;
    }
  }
`;

  // <Layout>
  //   <Wrapper>
  //     <Helmet title={`Categories | ${config.siteTitle}`} />
  //     <Header>
  //       <Link to="/">{config.siteTitle}</Link>
  //     </Header>
  //     <Content>
  //       <SectionTitle>Categories</SectionTitle>
  //       {group.map(category => (
  //         <Title key={category.fieldValue}>
  //           <Link to={`/categories/${kebabCase(category.fieldValue)}`}>{category.fieldValue}</Link> (
  //           {category.totalCount})
  //         </Title>
  //       ))}
  //     </Content>
  //   </Wrapper>
  // </Layout>

const IndexPage = (props) => (
  <Layout>
    <Wrapper>
    <Helmet title={`About Us | ${config.siteTitle}`} />
    <Header> 
    </Header>
      <Hero>
        <LogoImg src={logoImg} alt={"7 good gifts logo"} />
        <SecondaryH2>About Us</SecondaryH2>
        
      </Hero>
      <Content>
        <p>
        {`Like most people these days, we love lists... We like making them, and we like reading them. The one problem we've constantly run into is that most of the lists you see today are too long. Not ours. Seven is a good number - we'll always keep it that.`}
        </p>
        <p>
        {`If there's one thing we like more than lists - its gifts. Naturally, we put the two together and made 7 Good Gifts.`}
        </p>
        <p>
        {`Out mission with 7GG is to help you discover gifts people won't expect. Sure your friend is a wine lover - but for that very reason - they probably don't need (another) counter-top wine rack. Go for something special. Something quirky. Something memorable. A genuine good gift.`}
        </p>
        <Link to="/search" style={{"display": "block", "textAlign": "center", "margin": "1em"}}>
          <Button big>
            <svg
              width="1792"
              height="1792"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
            </svg>
            Let's Get Going
          </Button>
        </Link>
      </Content>
    </Wrapper>
  </Layout>
);

export default IndexPage;

// IndexPage.propTypes = {
//   data: PropTypes.shape({
//     allMdx: PropTypes.shape({
//       nodes: PropTypes.array.isRequired
//     })
//   }).isRequired
// };

// export const IndexQuery = graphql`
//   query IndexQuery {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt(pruneLength: 200)
//         timeToRead
//         fields {
//           slug
//         }
//         frontmatter {
//           title
//           date(formatString: "MM/DD/YYYY")
//           categories
//           primaryColor
//           banner {
//             ...bannerImage640
//           }
//         }
//       }
//     }
//   }
// `;
